import React from 'react';
import './Services.css';

function Services () {
    return (
        <div className='services-container'>
            <div className='services-inner-container'>
                <div className='services-container-head'>
                    SERVICES
                </div>
                <div className='services-container-sub-head'>
                    We offer a wide range of interior design services, including space planning, color consultations, furniture selection, lighting design, and more.
                </div>
                <div className='services-icons-container'>
                    <div className='services-icon-container'>
                    <svg width="58" height="32" viewBox="0 0 58 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M57.5939 18.1879H0.866638C1.25119 12.1534 2.72978 6.23899 5.23028 0.733398H53.2303C55.731 6.23893 57.2096 12.1533 57.5939 18.1879ZM9.59391 22.5516H48.8666V48.7334H44.503V26.9152H31.4121V48.7334H9.59391V22.5516ZM13.9575 35.6425H27.0485V26.9152H13.9575V35.6425Z" fill="#EECC7C"/>
                    </svg>
                        <div className='services-icon-name'>Styling & Decor</div>
                    </div>
                    <div className='services-icon-container'>
                    <svg width="61" height="32" viewBox="0 0 61 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.66254 12.9363V6.48633C9.66254 3.18633 12.378 0.486328 15.6968 0.486328H45.8683C49.1871 0.486328 51.9025 3.18633 51.9025 6.48633V12.9663C48.4027 14.1963 45.8683 17.4963 45.8683 21.3963V27.4863H15.6968V21.3663C15.6968 17.4963 13.1624 14.1663 9.66254 12.9363ZM54.9197 15.4863C51.6008 15.4863 48.8854 18.1863 48.8854 21.4863V30.4863H12.6797V21.4863C12.6797 19.895 12.0439 18.3689 10.9123 17.2437C9.78064 16.1185 8.24579 15.4863 6.6454 15.4863C5.04501 15.4863 3.51016 16.1185 2.37852 17.2437C1.24687 18.3689 0.611115 19.895 0.611115 21.4863V36.4863C0.611115 39.7863 3.32654 42.4863 6.6454 42.4863V48.4863H12.6797V42.4863H48.8854V48.4863H54.9197V42.4863C58.2385 42.4863 60.954 39.7863 60.954 36.4863V21.4863C60.954 18.1863 58.2385 15.4863 54.9197 15.4863Z" fill="#EECC7C"/>
                    </svg>
                        <div className='services-icon-name'>Residential Design</div>
                    </div>
                    <div className='services-icon-container'>
                    <svg width="52" height="32" viewBox="0 0 52 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M42.6267 45.3334V35.6667C45.3333 35.28 47.2667 32.96 47.2667 29.8667C47.2667 26.7734 44.56 24.0667 41.4667 24.0667C38.3733 24.0667 35.6667 26.7734 35.6667 29.8667C35.6667 32.5734 37.6 35.28 40.3067 35.6667V45.3334H24.0667V0.866699H4.73334V45.3334H0.866669V47.2667H51.1333V45.3334H42.6267ZM20.2 35.6667H8.6V31.8H20.2V35.6667ZM20.2 27.9334H8.6V24.0667H20.2V27.9334ZM20.2 20.2H8.6V16.3334H20.2V20.2ZM20.2 12.4667H8.6V8.60003H20.2V12.4667Z" fill="#EECC7C"/>
                    </svg>
                        <div className='services-icon-name'>Exterior Maintenance</div>
                    </div>
                    <div className='services-icon-container'>
                        <svg width="54" height="32" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M48.75 17.3335C50.0319 17.3335 51.2612 17.8427 52.1677 18.7492C53.0741 19.6556 53.5833 20.885 53.5833 22.1668V31.8335C53.5833 33.1154 53.0741 34.3448 52.1677 35.2512C51.2612 36.1576 50.0319 36.6668 48.75 36.6668H46.1835C45.5943 41.339 43.3204 45.6355 39.7884 48.7502C36.2564 51.8648 31.7091 53.5834 27 53.5835V48.7502C30.8456 48.7502 34.5338 47.2225 37.253 44.5032C39.9723 41.7839 41.5 38.0958 41.5 34.2502V19.7502C41.5 15.9045 39.9723 12.2164 37.253 9.49712C34.5338 6.77784 30.8456 5.25017 27 5.25017C23.1544 5.25017 19.4662 6.77784 16.7469 9.49712C14.0277 12.2164 12.5 15.9045 12.5 19.7502V36.6668H5.24999C3.96811 36.6668 2.73873 36.1576 1.83231 35.2512C0.925881 34.3448 0.416656 33.1154 0.416656 31.8335V22.1668C0.416656 20.885 0.925881 19.6556 1.83231 18.7492C2.73873 17.8427 3.96811 17.3335 5.24999 17.3335H7.81649C8.40623 12.6618 10.6804 8.36591 14.2124 5.2518C17.7443 2.13769 22.2912 0.419434 27 0.419434C31.7087 0.419434 36.2557 2.13769 39.7876 5.2518C43.3195 8.36591 45.5938 12.6618 46.1835 17.3335H48.75ZM16.7533 36.1472L19.315 32.0486C21.6183 33.4914 24.2821 34.2546 27 34.2502C29.7179 34.2546 32.3817 33.4914 34.685 32.0486L37.2467 36.1472C34.1757 38.0713 30.6239 39.0891 27 39.0835C23.3761 39.0891 19.8243 38.0713 16.7533 36.1472Z" fill="#EECC7C"/>
                        </svg>
                        <div className='services-icon-name'>24X7 Availability</div>
                    </div>
                    <div className='services-icon-container'>
                        <svg width="59" height="32" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M58.0225 55.3673L49.5 46.8973V14.3123L45.475 6.90979C45.1781 6.34541 44.7327 5.87288 44.1867 5.54323C43.6408 5.21359 43.0152 5.03936 42.3775 5.03936C41.7398 5.03936 41.1142 5.21359 40.5683 5.54323C40.0224 5.87288 39.5769 6.34541 39.28 6.90979L35.5 14.3298V32.9673L3.49253 1.11729C3.24776 0.870486 2.93503 0.70217 2.59422 0.633798C2.25341 0.565426 1.89996 0.600096 1.57894 0.733388C1.25791 0.86668 0.98385 1.09255 0.791698 1.38221C0.599546 1.67187 0.498008 2.01219 0.50003 2.35979V16.4998H4.29753V19.2998H0.50003V30.4998H4.29753V33.2998H0.50003V44.9373H4.29753V47.7373H0.50003V56.6098C0.50003 57.0739 0.684404 57.519 1.01259 57.8472C1.34078 58.1754 1.7859 58.3598 2.25003 58.3598H56.78C57.1276 58.3618 57.4679 58.2603 57.7576 58.0681C58.0473 57.876 58.2731 57.6019 58.4064 57.2809C58.5397 56.9599 58.5744 56.6064 58.506 56.2656C58.4376 55.9248 58.2693 55.6121 58.0225 55.3673ZM14.5 44.4998V28.6448L30.3725 44.4998H14.5ZM46 48.1923H39V43.9398H46V48.1923ZM46 41.1923H39V15.1873L42.395 8.58979L46 15.2048V41.1923Z" fill="#EECC7C"/>
                        </svg>
                        <div className='services-icon-name'>Consultation</div>
                    </div>
                    <div className='services-icon-container'>
                        <svg width="43" height="32" viewBox="0 0 43 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M39.875 29.125V5.5C39.875 2.60462 37.5204 0.25 34.625 0.25H8.375C5.47962 0.25 3.125 2.60462 3.125 5.5V29.125C2.42881 29.125 1.76113 29.4016 1.26885 29.8938C0.776562 30.3861 0.5 31.0538 0.5 31.75V52.75H5.75V39.625H37.25V52.75H42.5V31.75C42.5 31.0538 42.2234 30.3861 41.7312 29.8938C41.2389 29.4016 40.5712 29.125 39.875 29.125ZM34.625 5.5V29.125H29.375V5.5H34.625ZM24.125 5.5V29.125H18.875V5.5H24.125ZM8.375 5.5H13.625V29.125H8.375V5.5Z" fill="#EECC7C"/>
                        </svg>
                        <div className='services-icon-name'>Elegant Furniture</div>
                    </div>
                    <div className='services-icon-container'>
                        <svg width="45" height="32" viewBox="0 0 45 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6052 36.2158V29.8667C26.6052 29.3539 26.4015 28.8622 26.0389 28.4996C25.6764 28.1371 25.1846 27.9334 24.6719 27.9334H20.8052C20.2924 27.9334 19.8007 28.1371 19.4381 28.4996C19.0756 28.8622 18.8719 29.3539 18.8719 29.8667V36.2119C15.9335 36.9634 13.2817 38.5643 11.2482 40.8145C9.21457 43.0647 7.88934 45.8644 7.43813 48.8636C7.39403 49.1432 7.41099 49.429 7.48783 49.7014C7.56467 49.9737 7.69957 50.2263 7.88327 50.4416C8.06697 50.6568 8.2951 50.8298 8.552 50.9486C8.80889 51.0673 9.08845 51.129 9.37147 51.1295H36.1365C36.4195 51.129 36.6991 51.0673 36.956 50.9486C37.2129 50.8298 37.441 50.6568 37.6247 50.4416C37.8084 50.2263 37.9433 49.9737 38.0202 49.7014C38.097 49.429 38.114 49.1432 38.0699 48.8636C37.6157 45.8612 36.2856 43.0595 34.2462 40.8096C32.2068 38.5597 29.5488 36.9617 26.6052 36.2158ZM44.5427 21.2788L34.876 1.9455C34.7169 1.6226 34.4708 1.35054 34.1654 1.15994C33.8601 0.969345 33.5076 0.867785 33.1476 0.866699H12.3295C11.9714 0.867428 11.6205 0.967592 11.316 1.15603C11.0115 1.34446 10.7654 1.61376 10.6049 1.9339L0.938266 21.2672C0.790998 21.5611 0.72109 21.8877 0.735149 22.2161C0.749207 22.5445 0.846769 22.8639 1.01861 23.1441C1.19046 23.4243 1.43091 23.6561 1.71725 23.8175C2.00359 23.979 2.32636 24.0647 2.65507 24.0667H34.3385V29.8667C34.3385 30.3794 34.5422 30.8712 34.9048 31.2338C35.2674 31.5963 35.7591 31.8 36.2719 31.8C36.7846 31.8 37.2764 31.5963 37.6389 31.2338C38.0015 30.8712 38.2052 30.3794 38.2052 29.8667V24.0667H42.822C43.1494 24.0644 43.4709 23.979 43.7563 23.8184C44.0417 23.6579 44.2817 23.4275 44.4537 23.1488C44.6256 22.8702 44.724 22.5524 44.7396 22.2253C44.7552 21.8982 44.6874 21.5726 44.5427 21.2788Z" fill="#EECC7C"/>
                        </svg>
                        <div className='services-icon-name'>Aesthetic Components</div>
                    </div>
                    <div className='services-icon-container'>
                        <svg width="59" height="32" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.75 0.75L29.5 20.875H0.75L6.5 0.75M12.25 23.75H18V52.5H26.625V58.25H3.625V52.5H12.25V23.75ZM52.5 18L58.25 38.125H29.5L35.25 18M41 41H46.75V52.5H55.375V58.25H32.375V52.5H41V41Z" fill="#EECC7C"/>
                        </svg>
                        <div className='services-icon-name'>Commercial Design</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services