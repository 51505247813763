import React from 'react';
import './AboutUs.css';

function AboutUs () {
  return (
    <div className='about-container'>
      <div className='about-inner-container'>
        <div className='about-container-head'>
          ABOUT US
        </div>
        <div className='about-content'>
          <span className='about-content-main'>At <span style={{color: ""}}>Sri Hanuman Interiors & Decor</span>, we value creativity, innovation, and customer satisfaction above all else. </span>
          <div className='commercial-content-list'>
            <ul>
              <li><span>Our design process involves collaborating closely with our clients to understand their vision and preferences and develop a design plan that meets their expectations.</span></li>
              <li><span>We believe that every space has the potential to be transformed into a beautiful and functional environment, regardless of its size or shape.</span></li>
              <li><span>Our goal is to transform any space into a comfortable and aesthetically pleasing environment that meets our clients' needs and preferences.</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs