import React, { useEffect, useState, useRef } from 'react';
import './gallery.css';
import { app } from '../../firebase/firebaseConfig';
import { getStorage, ref, listAll } from 'firebase/storage';
import { BsArrowRight } from "react-icons/bs";
import { register } from 'swiper/element/bundle';

register();

function Gallery () {
    const [ guestDisplay, setGuestArray ] = useState( [] );
    const [ kidsDisplay, setKidsArray ] = useState( [] );
    const [ masterDisplay, setMasterArray ] = useState( [] );
    const [ falseCeilingandLightingDisplay, setCeilingArray ] = useState( [] );
    const [ foyerAreaDisplay, setFoyerArray ] = useState( [] );
    const [ homeDecorDisplay, setHomeDecorArray ] = useState( [] );
    const [ tvUnitDisplay, setTvUnitArray ] = useState( [] );
    const [ crockeryDisplay, setCrockeryArray ] = useState( [] );
    const [ diningDisplay, setDiningArray ] = useState( [] );
    const [ modularDisplay, setModularArray ] = useState( [] );
    const [ otherDisplay, setOtherArray ] = useState( [] );
    const [ wallPaintingDisplay, setWallPaintingArray ] = useState( [] );
    const [ wallDesignDisplay, setWallDesignArray ] = useState( [] );
    const swiperElRef = useRef( null );

    const storage = getStorage( app );
    function onload () {
        const getRefGuest = ref( storage, 'bedroom/guest' );
        listAll( getRefGuest ).then( ( element ) => {
            let guestArray = [];
            element.items.forEach( ( e ) => {
                guestArray.push( e.name );
            } );
            setGuestArray( [...guestArray] );
        } )

        const getRefKids = ref( storage, 'bedroom/kids' );
        listAll( getRefKids ).then( ( element ) => {
            let kidsArray = [];
            element.items.forEach( ( e ) => {
                kidsArray.push( e.name );
            } );
            setKidsArray( [...kidsArray] );
        } )

        const getRefMaster = ref( storage, 'bedroom/master' );
        listAll( getRefMaster ).then( ( element ) => {
            let masterArray = []
            element.items.forEach( ( e ) => {
                masterArray.push( e.name );
            } );
            setMasterArray( [ ...masterArray ] );
        } )

        const getRefFalseCeiling = ref( storage, 'living-room/false-ceiling-and-lighting' );
        listAll( getRefFalseCeiling ).then( ( element ) => {
            let falseCeilingandLightingArray = [];
            element.items.forEach( ( e ) => {
                falseCeilingandLightingArray.push( e.name );
            } );
            setCeilingArray( [...falseCeilingandLightingArray] );
        } )

        const getRefFoyerArea = ref( storage, 'living-room/foyer-area' );
        listAll( getRefFoyerArea ).then( ( element ) => {
            let foyerAreaArray = [];
            element.items.forEach( ( e ) => {
                foyerAreaArray.push( e.name );
            } );
            setFoyerArray( [...foyerAreaArray] );
        } )

        const getRefHomeDecor = ref( storage, 'living-room/home-decor' );
        listAll( getRefHomeDecor ).then( ( element ) => {
            let homeDecorArray = [];
            element.items.forEach( ( e ) => {
                homeDecorArray.push( e.name );
            } );
            setHomeDecorArray( [...homeDecorArray] );
        } )

        const getRefTvUnit = ref( storage, 'living-room/tv-unit' );
        listAll( getRefTvUnit ).then( ( element ) => {
            let tvUnitArray = [];
            element.items.forEach( ( e ) => {
                tvUnitArray.push( e.name );
            } );
            setTvUnitArray( [...tvUnitArray] );
        } )

        const getRefCrockery = ref( storage, 'kitchen/crockery' );
        listAll( getRefCrockery ).then( ( element ) => {
            let crockeryArray = [];
            element.items.forEach( ( e ) => {
                crockeryArray.push( e.name );
            } );
            setCrockeryArray( [...crockeryArray] );
        } )

        const getRefDining = ref( storage, 'kitchen/dining' );
        listAll( getRefDining ).then( ( element ) => {
            let diningArray = [];
            element.items.forEach( ( e ) => {
                diningArray.push( e.name );
            } );
            setDiningArray( [...diningArray] );
        } )

        const getRefModular = ref( storage, 'kitchen/modular' );
        listAll( getRefModular ).then( ( element ) => {
            let modularArray = [];
            element.items.forEach( ( e ) => {
                modularArray.push( e.name );
            } );
            setModularArray( [...modularArray] );
        } )

        const getRefOther = ref( storage, 'Other' );
        listAll( getRefOther ).then( ( element ) => {
            let otherArray = [];
            element.items.forEach( ( e ) => {
                otherArray.push( e.name );
            } );
            setOtherArray( [...otherArray] );
        } )

        const getRefWallDesign = ref( storage, 'paint/wall-design' );
        listAll( getRefWallDesign ).then( ( element ) => {
            let wallDesignArray = [];
            element.items.forEach( ( e ) => {
                wallDesignArray.push( e.name );
            } );
            setWallDesignArray( [...wallDesignArray] );
        } )

        const getRefWallPainting = ref( storage, 'paint/wall-painting' );
        listAll( getRefWallPainting ).then( ( element ) => {
            let wallPaintingArray = [];
            element.items.forEach( ( e ) => {
                wallPaintingArray.push( e.name );
            } );
            setWallPaintingArray( [...wallPaintingArray] );
        } )

        swiperElRef.current.addEventListener( 'progress', ( e ) => {
            const [ swiper, progress ] = e.detail;
            console.log( progress );
        } );

        swiperElRef.current.addEventListener( 'slidechange', ( e ) => {
            console.log( 'slide changed' );
        } );
    }

    useEffect( () => {
        onload();
    }, [] )

    return (
        <div class='gallery-main-container'>
            <div class='gallery-main-content-container'>
                <div class='gallery-content-head'>
                    <div class='head-title'>
                        SOME OF OUR <span>LATEST DESIGNS</span>
                    </div>
                </div>
                <div class='gallery-sub-headers-container'>
                    <div class='carousel-head-main'>
                        Bedroom
                    </div>
                    <div class='view-all-container display'>
                        <span><a href="/bedroom">View All</a></span>
                        <BsArrowRight />
                    </div>
                </div>
                <div class='carousel-head'>
                    Master Bedroom
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    // pagination="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {masterDisplay.map( ( data ) => (
                    <swiper-slide class="carousel-inner">
                        <div class='image-div-container'>
                            <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fmaster%2F"+data+"?alt=media&token=b7b064f6-1dd9-4f51-a14b-2d77358b4376"} alt="image1" />
                        </div>
                    </swiper-slide>
                    ) )}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {masterDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fmaster%2F" + data +"?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {masterDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fmaster%2F" + data +"?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='carousel-head'>
                    Guest Bedroom
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {guestDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fguest%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {guestDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fguest%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {guestDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fguest%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='carousel-head'>
                    Kids Bedroom
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {kidsDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fkids%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {kidsDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fkids%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {kidsDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/bedroom%2Fkids%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='gallery-sub-headers-container'>
                    <div class='carousel-head-main'>
                        Living Room
                    </div>
                    <div class='view-all-container display'>
                        <span><a href="/living-room">View All</a></span>
                        <BsArrowRight />
                    </div>
                </div>
                <div class='carousel-head'>
                    Home Decor
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {homeDecorDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Fhome-decor%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {homeDecorDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Fhome-decor%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {homeDecorDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Fhome-decor%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='carousel-head'>
                    False Ceiling and Lighting
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {falseCeilingandLightingDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ffalse-ceiling-and-lighting%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {falseCeilingandLightingDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ffalse-ceiling-and-lighting%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {falseCeilingandLightingDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ffalse-ceiling-and-lighting%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='carousel-head'>
                    Foyer Area
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {foyerAreaDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ffoyer-area%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {foyerAreaDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ffoyer-area%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {foyerAreaDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ffoyer-area%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='carousel-head'>
                    TV Unit
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {tvUnitDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ftv-unit%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {tvUnitDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ftv-unit%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {tvUnitDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/living-room%2Ftv-unit%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='gallery-sub-headers-container'>
                    <div class='carousel-head-main'>
                        Kitchen
                    </div>
                    <div class='view-all-container display'>
                        <span><a href="/kitchen">View All</a></span>
                        <BsArrowRight />
                    </div>
                </div>
                <div class='carousel-head'>
                    Dining Area
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {diningDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fdining%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {diningDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fdining%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {diningDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fdining%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='carousel-head'>
                    Modular Kitchen
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {modularDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fmodular%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {modularDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fmodular%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {modularDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fmodular%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='carousel-head'>
                    Crockery Unit
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {crockeryDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fcrockery%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {crockeryDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fcrockery%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {crockeryDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/kitchen%2Fcrockery%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='gallery-sub-headers-container'>
                    <div class='carousel-head-main'>
                        Others
                    </div>
                    <div class='view-all-container display'>
                        <span><a href="/others">View All</a></span>
                        <BsArrowRight />
                    </div>
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {otherDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/Other%2F" + data + "?alt=media&token=52762cce-47e8-441b-a2fa-5cd70feae236"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {otherDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/Other%2F" + data + "?alt=media&token=52762cce-47e8-441b-a2fa-5cd70feae236"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {otherDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/Other%2F" + data + "?alt=media&token=52762cce-47e8-441b-a2fa-5cd70feae236"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='gallery-sub-headers-container'>
                    <div class='carousel-head-main'>
                        Paint
                    </div>
                    <div class='view-all-container display'>
                        <span><a href="/paint">View All</a></span>
                        <BsArrowRight />
                    </div>
                </div>
                <div class='carousel-head'>
                    Wall Painting
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {wallPaintingDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/paint%2Fwall-painting%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {wallPaintingDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/paint%2Fwall-painting%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {wallPaintingDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/paint%2Fwall-painting%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <div class='carousel-head'>
                    Wall Design
                </div>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={4}
                    navigation="true"
                    class="gallery-carousel other-carousel normal"
                >
                    {wallDesignDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/paint%2Fwall-design%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={2}
                    navigation="true"
                    class="gallery-carousel other-carousel tablet"
                >
                    {wallDesignDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/paint%2Fwall-design%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
                <swiper-container
                    space-between={40}
                    ref={swiperElRef}
                    slides-per-view={1}
                    navigation="true"
                    class="gallery-carousel other-carousel mobile"
                >
                    {wallDesignDisplay.map((data, i) => (
                        <swiper-slide key={i} class="carousel-inner">
                            <div class='image-div-container' key={i}>
                                <img src={"https://firebasestorage.googleapis.com/v0/b/she-interiors.appspot.com/o/paint%2Fwall-design%2F" + data + "?alt=media&token=20dc1d0b-f98d-4f11-8e89-9bc12c1bafbe"} key={i} alt="image1" />    
                            </div> 
                        </swiper-slide>
                    ))}
                </swiper-container>
            </div>
        </div>
    )
}

export default Gallery