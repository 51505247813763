import React, { useRef } from 'react';
import './Hero.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import { AiFillCheckCircle } from "react-icons/ai";


function Hero() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_a6oebmd', 'template_bpf0umt', form.current, 'lNceVvdcKvmFkKefE')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      let inputs = document.querySelectorAll(".form-control");
      for (let i = 0; i<inputs.length; i++) {
          inputs[i].value = "";
      }

    document.querySelector(".message-sent").style.display = "flex";

    setTimeout(function(){
      document.querySelector(".message-sent").style.display = "none";
    }, 4000);
  };

  return (
    <Container id="home" className='hero-container'>
      <Row>
        <Col sm>
          <div className='hero-box'>
            <div className="hero-box-text-1">
              Sri Hanuman Interiors & Decor
            </div>
            <div className='hero-box-text'>
              We design Modular Kitchens, Furniture, Furnishings <br /> and Décor Accessories and what not to bring that <br /> smile on your face whether it's DAY 1 in your newly <br /> furnished home or Day 10,000
            </div>
          </div>
        </Col>
        <Col sm>
          <div className='hero-form'>
            <div className='main-form-container'>
              <span className='form-head-title'>Meet a Designer</span>
              <form className='main-form' ref={form} onSubmit={sendEmail}>
                <Form.Control type="text" placeholder="Your Name" name='user_name' required />
                <Form.Control type="email" placeholder="Your Email Id" name='user_email' required />
                <Form.Control type="tel" placeholder="Enter Your Mobile Number" name='user_phone' pattern='[0-9]{10}' required />
                <span className='message-sent'><AiFillCheckCircle />&nbsp;&nbsp;Message Sent</span>
                <button type='submit' className='hero-btn'>Book a Free Design Session</button>
              </form>
            </div>
            <div className='main-form-container-lower'>
              <div className='form-container-lower-head'>
                Why Choose Us?
              </div>
              <div className='form-container-lower-images'>
                <img src={require("../../images/hero-page-image-1.webp")} alt="" />
                <img src={require("../../images/hero-page-image-2.webp")} alt="" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className='whatsapp-icon-container'>
        <a href="https://wa.me/919611119067" target="_blank" rel="noreferrer">
          <img src={require("../../images/WhatsApp.png")} alt="whatsapp-icon" />
        </a>
      </div>
    </Container>
  )
}

export default Hero